<template>
  <div class="spinner-wrap">
    <div class="spinner" />
    <div class="spinner-desc">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpinnerIcon'
}
</script>

<style lang='scss'>
.spinner-wrap {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .spinner-desc {
    display: none;
  }
  &.with-message {
    .spinner-desc {
      display: block;
      margin: var(--padding-mini) 0;
      background-color: var(--media-item-bg-color, #000);
      color: var(--media-item-color, #fff);
      padding: 0 var(--padding-mini);
    }
  }
  .spinner {
    --spin-size: var(--spinner-size, var(--size-half));
    width: var(--spin-size);
    height: var(--spin-size);
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    animation: spin 0.8s linear infinite;
    border-width: calc(var(--spin-size) / 2);
    border-color: #fff #000;
    border-style: solid;
  }
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
}
</style>
